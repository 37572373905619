.comment-list {
  padding: 20px;
}

.comment-item {
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.comment-right {
  flex-direction: row-reverse;
}

.comment-bubble {
  background: #f0f2f5;
  border-radius: 12px;
  padding: 12px 16px;
  max-width: 80%;
  word-wrap: break-word;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.comment-right .comment-bubble {
  background: #1890ff;
  color: white;
  margin-left: auto;
}

.comment-left .comment-bubble {
  background: #f0f2f5;
  margin-right: auto;
}

.comment-time {
  font-size: 12px;
  color: #8c8c8c;
  margin-top: 4px;
}

.comment-footer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.delete-btn {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.comment-item:hover .delete-btn {
  opacity: 1;
}

.comment-editor {
  background: white;
  border-top: 1px solid #f0f0f0;
  padding: 16px;
  position: relative;
}

.comment-form {
  margin-bottom: 0;
  display: flex;
  gap: 12px;
}

.comment-input {
  border-radius: 8px;
  resize: none;
  padding: 8px 12px;
  transition: all 0.3s ease;
}

.comment-input:focus {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.send-button {
  width: 50px !important;
  align-self: flex-end;
  border-radius: 8px;
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.send-button i {
  font-size: 14px;
}

/* 添加滚动条样式 */
.comment-list::-webkit-scrollbar {
  width: 6px;
}

.comment-list::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 3px;
}

.comment-list::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.chat-container {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 800px;
  min-width: 320px;
  overflow: hidden;
}

.chat-header {
  padding: 16px 24px;
  border-bottom: 1px solid #f0f0f0;
  background: #fff;
  border-radius: 8px 8px 0 0;
}

.chat-header-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.chat-title {
  font-size: 16px;
  font-weight: 500;
  color: #1f1f1f;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  background: #f7f9fc;
  scroll-behavior: smooth;
  border: none;
}

.message-wrapper {
  display: flex;
  margin-bottom: 24px;
  position: relative;
  gap: 16px;
  align-items: flex-start;
  width: 100%;
}

.message-right {
  flex-direction: row-reverse;
}

.message-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  min-width: 40px;
}

.current-user-avatar,
.other-user-avatar {
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.current-user-avatar {
  background-color: #1890ff !important;
}

.other-user-avatar {
  background-color: #f56a00 !important;
}

.message-author {
  font-size: 13px;
  color: #666;
  max-width: 80px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.message-content {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.message-bubble {
  padding: 12px 16px;
  border-radius: 16px;
  position: relative;
  line-height: 1.6;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  font-size: 14px;
}

.message-text {
  word-break: break-word;
  white-space: pre-wrap;
}

.message-left .message-bubble {
  background: #fff;
  border-top-left-radius: 4px;
  margin-left: 8px;
}

.message-right .message-bubble {
  background: #1890ff;
  color: white;
  border-top-right-radius: 4px;
  margin-right: 8px;
}

.message-right .message-bubble::after,
.message-left .message-bubble::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border: 8px solid transparent;
}

.message-right .message-bubble::after {
  right: -8px;
  border-left-color: #1890ff;
}

.message-left .message-bubble::after {
  left: -8px;
  border-right-color: #fff;
}

.message-footer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 4px;
}

.message-time {
  font-size: 12px;
  color: #999;
}

.message-right .message-time {
  margin-left: auto;
}

.delete-btn {
  opacity: 0;
  transition: all 0.2s ease;
  color: #ff4d4f;
  padding: 4px;
  height: 24px;
  min-width: 24px;
  border-radius: 4px;
}

.delete-btn:hover {
  background: rgba(255, 77, 79, 0.1);
}

.message-wrapper:hover .delete-btn {
  opacity: 1;
}

.message-editor {
  background: #fff;
  border-top: 1px solid #f0f0f0;
  padding: 16px 24px;
  border-radius: 0 0 8px 8px;
}

.message-form {
  margin: 0;
}

.message-input-wrapper {
  display: flex;
  gap: 12px;
  align-items: flex-end;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  padding: 8px 12px;
  transition: all 0.3s ease;
}

.message-input-wrapper:focus-within {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
}

.message-input {
  flex: 1;
  border: none !important;
  padding: 8px 0 !important;
  background: transparent !important;
  resize: none;
  min-height: 36px;
  max-height: 150px;
  line-height: 1.6;
  font-size: 14px;
  overflow-y: auto !important;
}

.message-input:focus {
  box-shadow: none !important;
}

/* 确保 TextArea 的容器不会影响布局 */
.ant-input-textarea-show-count::after {
  display: none;
}

.ant-input-textarea {
  max-height: 150px;
}

.send-button {
  min-width: 36px;
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
}

.send-button i {
  font-size: 14px;
}

/* 滚动条样式 */
.messages-container::-webkit-scrollbar {
  width: 4px;
}

.messages-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.messages-container::-webkit-scrollbar-track {
  background: transparent;
}

.messages-container:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

/* 加载动画样式 */
.ant-spin {
  max-height: none !important;
}

/* 响应式调整 */
@media (max-width: 576px) {
  .message-content {
    max-width: 85%;
  }

  .message-avatar {
    min-width: 32px;
  }

  .message-author {
    max-width: 60px;
    font-size: 12px;
  }

  .message-bubble {
    padding: 10px 14px;
    font-size: 13px;
  }

  .messages-container {
    padding: 16px;
  }

  .message-input-wrapper {
    min-height: 48px;
    padding: 6px 10px;
  }

  .send-button {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }
}

/* 修改 Tooltip 样式 */
.ant-tooltip-inner {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.85) !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
}

.ant-tooltip-arrow-content {
  background-color: #fff !important;
}
